<template>
    <modal ref="modalEditarEntrega" v-loading="loading" titulo="Editar Entrega" icon="clock" @guardar="cambiar_fecha_masivo">
        <div class="row f-14 my-2 mx-0 mb-3 justify-center">
            <div class="col">
                <p class="ml-2 text-general">Fecha de Entrega</p>
                <el-date-picker
                v-model="model.fecha_nueva"
                type="date"
                size="small"
                placeholder="Fecha"
                value-format="yyyy-MM-dd"
                value="yyyy-MM-dd"
                :clearable="false"
                :picker-options="pickerOptions"
                @change="set_horario"
                />
            </div>
            <div class="col">
                <p class="ml-2 text-general">Hora de Entrega</p>
                <el-select v-model="model.horario" size="small" placeholder="Horario">
                    <el-option
                    v-for="item in horarios"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-admin'
export default {
    props:{
        fecha:{
            type:String,
            default:'',
            // required:false
        },
        aTiempo:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            loading:false,
            horarios:[],
            model:{
                exacta:false,
                fecha_antigua:'',
                fecha_nueva:'',
                horario:''
            }
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_cedis_navbar:'cedis/id_cedis_navbar',
        })
    },
    methods: {
        async toggle(){
            if(this.id_cedis_navbar == 0){
                return
            }
            await setTimeout(function(){  }, 1000);
            if(this.aTiempo){
                this.model.fecha_nueva = this.fecha
                this.model.fecha_antigua = this.fecha
                this.model.exacta = true
            }else{
                this.model.fecha_nueva = ''
                this.model.fecha_antigua = ''
                this.model.exacta = false
            }

            this.set_franja()
            this.$refs.modalEditarEntrega.toggle();
        },
        async cambiar_fecha_masivo(){
            try {
                this.loading = true
                const {data} = await Pedidos.cambiar_fecha_masivo(this.model)
                this.notificacion('Éxito','Registros actualizados correctamente','success')
                this.$emit('update')
                this.$refs.modalEditarEntrega.toggle();
            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
        async set_franja(){
            try {

                const {data} = await Pedidos.get_horarios(this.id_cedis_navbar)
                data.horarios.map(h=>{
                    h.disabled = false
                })
                this.horarios = data.horarios

            } catch (e){
                this.error_catch(e)
            }
        },
        set_horario(fecha){
            // console.log(fecha);
            let hoy = moment().format('YYYY-MM-DD')
            let hora = moment().hour()

            if(hoy === fecha){
                this.horarios.map(h=>{
                    if(h.fin < hora){
                        h.disabled=true
                    }
                })

            }else{
                this.horarios.map(h=>{
                    h.disabled=false
                })
            }

            this.model.horario = ''
        }
    }
}
</script>
